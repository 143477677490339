class AddToCartEvent {
    constructor() {
        (window as any).dataLayer = (window as any).dataLayer || [];
    }

    init = () => {
        document.addEventListener("DOMContentLoaded", this.initEvent);
    };

    initEvent = () => {
        (window as any).prestashop.on("updateCart", (details: any) => {
            if (!details.reason) {
                return;
            }

            if (details.reason.linkAction !== "add-to-cart") {
                return;
            }

            if (this.isProductPage()) {
                this.pushProductEvent();

                return;
            }

            const idProduct = details.reason.idProduct;

            if (!idProduct) {
                return;
            }

            this.pushCommonEvent(idProduct);
        });
    };

    isProductPage = () => {
        return document.body.id === "product";
    };

    pushProductEvent = () => {
        if (!(window as any).arkonGoogleAnalytics.view_item) {
            return;
        }

        (window as any).dataLayer.push({
            ...(window as any).arkonGoogleAnalytics.view_item,
            event: "add_to_cart",
        });
    };

    pushCommonEvent = (idProduct: any) => {
        if (!idProduct) {
            return;
        }

        const currencyIsoCode = (window as any).prestashop.currency.iso_code || "";

        (window as any).dataLayer.push({
            event: "add_to_cart",
            ecommerce: {
                currency: currencyIsoCode,
                items: [
                    {
                        item_id: idProduct,
                    },
                ],
            },
        });
    };
}

(new AddToCartEvent()).init();